<template>
  <b-modal
    v-model="showModal"
    @hidden="$emit('close-modal')"
    hide-footer
    title="Received requests"
  >
    <b-container>
      <b-row
        v-for="(request, index) in slotData.invitation_request_details"
        @click="openInvitation(request, index)"
        :key="index"
        ><b-col
          ><div class="request">
            <img
              :src="request.requested_by_details.profile_picture"
              alt="user"
            />Meeting Request from
            <b>{{
              `${request.requested_by_details.user.first_name} ${request.requested_by_details.user.last_name}`
            }}</b>
          </div></b-col
        ></b-row
      >
      <b-modal
        v-model="showInvitation"
        :id="`${invitation.id}`"
        hide-footer
        v-if="invitation"
        :title="`Meeting Request from ${invitation.requested_by_details.user.first_name} ${invitation.requested_by_details.user.last_name}`"
      >
        <b-container>
          <div>
            <p>
              <router-link
                :to="{ name: 'standardEvent', params: { eventId: event.id, slug: event.slug  } }"
                style="color: #bd9d5f"
              >
                {{ event.name }}
              </router-link>
            </p>
            <h4>
              <div>
               {{ startTime }} - {{ endTime }}
              </div>
            </h4>
            <div class="user-details">
              <img
                :src="invitation.requested_by_details.profile_picture"
                alt=""
                class="invitation-req-image"
              />
              <div class="userInfo">
                <router-link
                  :to="{
                    name: 'userProfilePage',
                    params: {
                      customerId: invitation.requested_by_id,
                    },
                  }"
                >
                  <span class="userName">
                    {{
                      `${invitation.requested_by_details.user.first_name} ${invitation.requested_by_details.user.last_name}`
                    }}
                  </span>
                </router-link>
                <span class="userPosition">
                  {{ invitation.requested_by_details.job_title }}
                </span>
                <router-link
                  :to="{
                    name: 'companyProfile',
                    params: {
                      companyId: invitation.requested_by_details.company_id,
                    },
                  }"
                >
                  <span class="userCompany">
                    {{ invitation.requested_by_details.company.name }}
                  </span>
                </router-link>
              </div>
            </div>
            <p>
              {{ invitation.meeting_details.message }}
            </p>
            <p>
              Meeting Location:
              {{ invitation.meeting_details.location }}
            </p>
            <p v-if="!invitation.approved && invitation.is_proceeded">
              Status: <span style="color: red">Refused</span>
            </p>
          </div>
          <div class="button-box" v-if="!invitation.is_proceeded">
            <b-button
              variant="outline-success"
              @click="acceptInvite(invitation.id)"
            >
              Accept
            </b-button>
            <b-button
              variant="outline-danger"
              @click="rejectInvite(invitation)"
            >
              Refuse
            </b-button>
          </div>
          <b-modal
            id="mpCongratModal"
            hide-footer
            @hidden="() => onModalHide(invitation.id)"
          >
            <div class="congrat">
              <p>CONGRATULATIONS!</p>
              <p>Your Meeting has been successfully accepted.</p>
            </div>
            <div
              style="height: 7vh; width: 100%; border-top: 1px solid #eee"
            ></div>
          </b-modal>
          <b-modal
            id="mpRefuseModal"
            hide-footer
            size="lg"
            @hidden="() => onModalHide(invitation.id)"
          >
            <div class="congrat">
              <p class="refuse-title">The meeting request has been successfully refused.</p>
              <p class="refuse-content">
                Optionally you can explain to the inviting Member the reason of
                your refusal.
              </p>
            </div>
            <div style="width:100%; padding: 0 10px; text-align: right;">
              <b-textarea no-resize v-model="refusalMessage"></b-textarea>
              <b-button @click="sendMessage" variant="info" class="mt-3">
                Send
              </b-button>
          </div>
            <hr />
            <div class="congrat">
              <p>Annoyed by this invitation?</p>
              <p>
                You can mark the inviting Member as spam. <br />
                This will prevent to receive further meeting invitations for
                this event.
              </p>
              <b-button
                variant="outline-danger"
                @click="addToSpamList(invitation.requested_by_details)"
              >
                MARK AS SPAM
              </b-button>
            </div>
          </b-modal>
        </b-container>
      </b-modal>
    </b-container>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import errorHelper from '../../../utils/errorHelper';

export default {
  name: 'InvitationsModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    slotData: {
      type: Object,
      default: () => {},
    },
    dayIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      showInvitation: false,
      invitation: null,
      refusalMessage: '',
      selectedInvitation: null,
    };
  },
  computed: {
    ...mapGetters({
      event: 'events/fetchEvent',
    }),

    startTime() {
      if (this.invitation && this.invitation.meeting_planner_slots.length) {
        return this.invitation.meeting_planner_slots[0].start.slice(0, 5);
      }
      return this.slotData.start.slice(0, 5);
    },
    endTime() {
      if (this.invitation && this.invitation.meeting_planner_slots.length) {
        return this.invitation.meeting_planner_slots[this.invitation.meeting_planner_slots.length - 1].end.slice(0, 5);
      }
      return this.slotData.end.slice(0, 5);
    },
  },
  watch: {
    value: {
      handler(show) {
        this.showModal = show;
      },
    },
    invitation() {
      this.invitation.meeting_planner_slots.sort((a, b) => a.id - b.id);
    },
  },
  methods: {
    ...mapActions({
      acceptOrRefuseInvitation: 'meetingPlanner/acceptOrRefuseInvitation',
      fetchMeetingPlanner: 'meetingPlanner/fetchMeetingPlanner',
      addRemoveSpam: 'user/addRemoveSpam',
      createNewConversation: 'messages/createNewConversation',
      sendMessageViaNodeJSServer: 'messages/sendMessageViaNodeJSServer',
    }),
    openInvitation(invitation, index) {
      this.showInvitation = true;
      this.invitation = invitation;
      this.invitationIndex = index;
    },
    onModalHide(invitationId) {
      this.$bvModal.hide(`${invitationId}`);
      this.fetchMeetingPlanner(this.$route.params.eventId);
      this.selectedInvitation = null;
    },
    async acceptInvite(id) {
      try {
        await this.acceptOrRefuseInvitation({
          invitationResponse: { id, approved: true },
          startTime: `${this.startTime}:00`,
          endTime: `${this.endTime}:00`,
          dayIndex: this.dayIndex,
          invitationIndex: this.invitationIndex,
        });
        this.$bvModal.show('mpCongratModal');
      } catch (err) {
        errorHelper(err.response.data).forEach((message) => {
          this.$notify({
            group: 'userAlerts',
            title: 'Error',
            text: message,
            type: 'error',
          });
        });
      }
    },
    async rejectInvite(invitation) {
      try {
        this.selectedInvitation = invitation;
        await this.acceptOrRefuseInvitation({
          invitationResponse: { id: invitation.id, approved: false },
          startTime: `${this.startTime}:00`,
          endTime: `${this.endTime}:00`,
          dayIndex: this.dayIndex,
          invitationIndex: this.invitationIndex,
        });
        this.$bvModal.show('mpRefuseModal');
      } catch (err) {
        errorHelper(err.response.data).forEach((message) => {
          this.$notify({
            group: 'userAlerts',
            title: 'Error',
            text: message,
            type: 'error',
          });
        });
      }
    },
    async addToSpamList({ id }) {
      try {
        await this.addRemoveSpam({
          customer: `${process.env.VUE_APP_API_HOST}/customers/${id}/`,
          action: 'add',
          customer_id: id,
        });
        this.$bvModal.hide('mpRefuseModal');
      } catch (err) {
        errorHelper(err.response.data).forEach((message) => {
          this.$notify({
            group: 'userAlerts',
            title: 'Error',
            text: message,
            type: 'error',
          });
        });
      }
    },
    async sendMessage() {
      if (!this.refusalMessage) return;
      try {
        const { result } = await this.createNewConversation({
          receiver_id: this.selectedInvitation.requested_by_details.user_id,
        });
        const messageData = {
          chat_room_id: result.chat_room_id,
          content: this.refusalMessage,
          receiver_id: this.selectedInvitation.requested_by_details.user_id,
          is_online_receiver: false,
        };
        this.sendMessageViaNodeJSServer(messageData);
        this.refusalMessage = '';
        this.$notify({
            group: 'userAlerts',
            title: 'Success',
            text: 'Message sent successfully!',
            type: 'success',
        });
      } catch (err) {
        this.$notify({
          group: 'userAlerts',
          title: 'Warning',
          text: err,
          type: 'warn',
        });
      }
    },
  },
};
</script>

<style scoped>
.request {
  border-top: 1px solid #ebebeb;
  padding: 5px 0;
  cursor: pointer;
}
.request > img {
  width: 25px;
  height: 100%;
  margin-right: 5px;
}
.request:hover {
  color: #bd9d5f;
}
.button-box > button {
  height: 30px;
  padding: 3px 5px;
  border-radius: unset;
}
.button-box {
  border-top: 1px solid #ebebeb;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.card {
  width: 180px;
}

.card-body {
  padding: 0;
}
.card-text > img {
  width: 100%;
}
.card-header {
  padding: unset;
  height: 29px;
  font-size: 15px;
  color: gray;
  text-align: center;
}
.card-footer {
  padding: unset;
  height: 29px;
  font-size: 15px;
  color: gray;
  text-align: center;
}

.userName {
  color: #333 !important;
  position: relative;
  font-weight: 900 !important;
  font-size: 12px !important;
  text-transform: capitalize;
  cursor: pointer;
}
.userCompany {
  color: #bd9d5f !important;
  text-transform: capitalize;
  font-size: 12px !important;
}
.userPosition {
  color: #666;
  font-size: 12px !important;
  text-transform: capitalize;
  max-height: 23px;
  overflow: hidden;
}

.user-details {
  display: flex;
  position: relative;
  max-width: 300px;
  margin: 5px;
}
.invitation-req-image {
  width: 62px;
  height: 62px;
  object-fit: cover;
}
.userInfo {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1%;
  width: 82%;
}
.userPosition {
  font-size: 10px;
  color: #666;
}
.userCompany {
  font-size: 10px;
  color: #bd9d5f;
  cursor: pointer;
}

.congrat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.congrat > p:last-child {
  font-size: 18px;
}
.congrat > p {
  color: #999;
  text-align: center;
}
.congrat > p:first-child {
  font-size: 20px;
  letter-spacing: 0px;
  font-weight: 500;
}
.refuse-title {
  color: #999999;
}
.refuse-content {
 color: rgb(224, 62, 45) !important;
}
</style>
