const errorHelper = (errorObject) => {
  if (Array.isArray(errorObject)) return errorObject.filter(s => typeof s === 'string');
  if (typeof errorObject !== 'object') return ['Something went wrong'];
  let arrayOfStrings = [];
  Object.values(errorObject).forEach((value) => {
    arrayOfStrings = (typeof value === 'string')
      ? [...arrayOfStrings, value]
      : [
        ...arrayOfStrings,
        ...(Array.isArray(value)
          ? value.filter(s => typeof s === 'string')
          : errorHelper(value)),
      ];
  });
  return arrayOfStrings;
};

export default errorHelper;
